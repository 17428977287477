<template>
    <div v-if="component">
        <component :is="component" v-if="pageData?.data" v-bind="pageData?.data" />
    </div>
</template>

<script setup>
import InformationalFragment from '@fragments/pages/InformationalFragment';
import NewsletterFragment from '@fragments/pages/NewsletterFragment';
import DirectoryFragment from '@fragments/pages/DirectoryFragment';
import StaffFragment from '@fragments/pages/StaffFragment';
import DonationPortalFragment from '@fragments/pages/DonationPortalFragment';
import BlogFragment from '@fragments/pages/BlogFragment';
import EditionFragment from '@fragments/pages/EditionFragment';
import PostFragment from '@fragments/pages/PostFragment';
import ResearchFragment from '@fragments/pages/ResearchFragment';
import CenterFragment from '@fragments/pages/CenterFragment';
import CollectionFragment from '@fragments/pages/CollectionFragment';
import EventCollectionFragment from '@fragments/pages/EventCollectionFragment';

const props = defineProps({
    contentType: {
        type: String,
    },
});

const fragmentComponentMap = {
    informationals: [InformationalFragment, 'InformationalPage'],
    newsletters: [NewsletterFragment, 'NewsletterPage'],
    directories: [DirectoryFragment, 'DirectoryPage'],
    staff: [StaffFragment, 'StaffPage'],
    donationPortals: [DonationPortalFragment, 'DonationPortalPage'],
    blogs: [BlogFragment, 'BlogPage'],
    editions: [EditionFragment, 'EditionPage'],
    posts: [PostFragment, 'PostDetailPage'],
    research: [ResearchFragment, 'ResearchDetailPage'],
    centers: [CenterFragment, 'CenterPage'],
    collections: [CollectionFragment, 'CollectionPage'],
    eventCollections: [EventCollectionFragment, 'EventCollectionPage'],
};

const isValidComponent = !!fragmentComponentMap[props.contentType];

let pageData = null;
let component = null;

if (isValidComponent) {
    const fragment = fragmentComponentMap[props.contentType][0];
    const componentName = fragmentComponentMap[props.contentType][1];

    // Now get the page data and render the page
    pageData = await usePageData(fragment, props.contentType);

    component = computed(() => {
        return defineAsyncComponent({
            loader: () => import(`../../components/pages/${componentName}.vue`),
        });
    });
} else {
    const { redirectRoute, invalidUrlOptions } = await handleInvalidUrl();
    if (redirectRoute?.path) {
        navigateTo(redirectRoute, invalidUrlOptions);
    } else {
        const error = { statusCode: 404 };
        showError(error);
        throw createError(error);
    }
}
</script>
