import basePageFields from '@fragments/pages/basePageFields';

import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import StreamContainedFragment from '@fragments/shared/StreamContainedFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

import BlogSummaryFragment from '@fragments/summaries/BlogSummaryFragment';
import CenterSummaryFragment from '@fragments/summaries/CenterSummaryFragment';
import ProgramsSummaryFragment from '@fragments/summaries/ProgramsSummaryFragment';
import ProjectsSummaryFragment from '@fragments/summaries/ProjectsSummaryFragment';

import TagFragment from '@fragments/pages/TagFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment EditionFragment on Edition {
        ${basePageFields}
        contributors {
            ...PersonSummaryFragment
        }
        dek
        publishedAt
        parent {
            value {
                ... on Newsletter {
                    title
                    dek
                    path
                    logo {
                        ...ImageSummaryFragment
                    }
                    hero {
                        image {
                            ...ImageSummaryFragment
                        }
                    }
                }
            }
        }
        streamContained {
            ...StreamContainedFragment
        }
        tags {
            ...TagFragment
        }
        seo {
            ...SeoFragment
        }
        contributors {
            ...PersonSummaryFragment
        }
        supplementalLinks {
            link {
                type
                label
                url
            }
        }
    }

    ${PersonSummaryFragment}
    ${StreamContainedFragment}
    ${ImageSummaryFragment}
    ${TagFragment}
    ${SeoFragment}
`;
