<template>
    <PageParser v-if="!isPending && type !== null" :contentType="type || 'not-found'" />
</template>

<script setup>
const route = useRoute();
let type = null;
let isPending = null;

const normalizedPath = route.path.endsWith('/') ? route.path : route.path + '/';

if (['/', '/china/', '/india/', '/russia-eurasia/', '/middle-east/', '/europe/'].includes(normalizedPath)) {
    type = 'centers';
} else {
    // Get the content type of the current path
    const { contentType, pending } = await usePageType(route.path);
    type = contentType;
    isPending = pending;
}
</script>
