import basePageFields from '@fragments/pages/basePageFields';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';
import SubnavFragment from '@fragments/shared/SubnavFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment BlogFragment on Blog {
        ${basePageFields}
        headline
        dek
        accentColor
        heroBlog {
            heroImage {
                ...ImageSummaryFragment
            }
            mobileHeroImage {
                ...ImageSummaryFragment
            }
            logo {
                ...ImageSummaryFragment
            }
            tagline
        }
        subNav {
            ...SubnavFragment
        }
        heroContentTier {
            ...ContentDynamicSortableFragment
        }
        featuredPodcastEpisode
        featuredContent {
            ...ContentDynamicSortableFragment
        }
        streamTiered {
            ...StreamTieredFragment
        }
        seo {
            ...SeoFragment
        }
    }
    ${SubnavFragment}
    ${ImageSummaryFragment}
    ${StreamTieredFragment}
    ${ContentDynamicSortableFragment}
    ${SeoFragment}
`;
