import basePageFields from '@fragments/pages/basePageFields';

import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';
import HeroBlogFragment from '@fragments/shared/HeroBlogFragment';
import HeroProgramsProjectsFragment from '@fragments/shared/HeroProgramsProjectsFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SubnavFragment from '@fragments/shared/SubnavFragment';
import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment InformationalFragment on Informational {
        ${basePageFields}
        dek
        hero {
            image {
                ...ImageSummaryFragment
            }
        }
        streamTiered {
            ...StreamTieredFragment
        }
        subNav {
            ...SubnavFragment
        }
        parent {
            relationTo
            value {
                ... on Center {
                    title
                    heroCarousel {
                        ...ContentDynamicSortableFragment
                    }
                }
                ... on Informational {
                    title
                    hero {
                        image {
                            ...ImageSummaryFragment
                        }
                    }
                    subNav {
                        ...SubnavFragment
                    }
                }
                ... on Blog {
                    title
                    heroBlog {
                        ...HeroBlogFragment
                    }
                    subNav {
                        ...SubnavFragment
                    }
                }
                ... on Program {
                    title
                    heroProgramsProjects {
                        ...HeroProgramsProjectsFragment
                    }
                    subNav {
                        ...SubnavFragment
                    }
                }
                ... on Project {
                    title
                    heroProgramsProjects {
                        ...HeroProgramsProjectsFragment
                    }
                    subNav {
                        ...SubnavFragment
                    }
                }
            }
        }
        seo {
            ...SeoFragment
        }
    }

    ${ContentDynamicSortableFragment}
    ${HeroBlogFragment}
    ${HeroProgramsProjectsFragment}
    ${ImageSummaryFragment}
    ${SubnavFragment}
    ${StreamTieredFragment}
    ${SeoFragment}
`;
