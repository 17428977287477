const typeQuery = () => gql`
    query type($path: String!) {
        type(path: $path) {
            _id
            contentType
            subtype
        }
    }
`;

export default typeQuery;
