import typeQuery from '@gql/queries/typeQuery';

export default async function (path) {
    const type = ref();

    const query = typeQuery();
    const variables = { path };
    const { data, pending } = await useAsyncQuery(query, variables);
    type.value = data?.value.type?.contentType;

    writeLastQuery(query, variables, 'pageTypeQuery');

    return { contentType: type, isPending: pending };
}
