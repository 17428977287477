import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment HeroBlogFragment on HeroBlog {
        heroImage {
            ...ImageSummaryFragment
        }
        mobileHeroImage {
            ...ImageSummaryFragment
        }
        logo {
            ...ImageSummaryFragment
        }
        tagline
    }

    ${ImageSummaryFragment}
`;
