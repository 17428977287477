import basePageFields from '@fragments/pages/basePageFields';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';
import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';

import TopicSummaryFragment from '@fragments/summaries/TopicSummaryFragment';
import RegionSummaryFragment from '@fragments/summaries/RegionSummaryFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment CenterFragment on Center {
        ${basePageFields}
        heroCarousel {
            ...ContentDynamicSortableFragment
        }
        about
        topicRegionContentFeed {
            topicOrRegion {
                value {
                    ...TopicSummaryFragment
                    ...RegionSummaryFragment
                }
            }
            featuredContent {
                ...ContentDynamicSortableFragment
            }
        }
        streamTiered {
            ...StreamTieredFragment
        }
        trendingTopicRegionTag {
            value {
                ...TopicSummaryFragment
                ...RegionSummaryFragment
            }
        }
        seo {
            ...SeoFragment
        }
    }

    ${ContentDynamicSortableFragment}
    ${StreamTieredFragment}

    ${TopicSummaryFragment}
    ${RegionSummaryFragment}
    ${SeoFragment}
`;
