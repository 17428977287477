import basePageFields from '@fragments/pages/basePageFields';

import BlogSummaryFragment from '@fragments/summaries/BlogSummaryFragment';
import CenterSummaryFragment from '@fragments/summaries/CenterSummaryFragment';
import ProgramsSummaryFragment from '@fragments/summaries/ProgramsSummaryFragment';
import ProjectsSummaryFragment from '@fragments/summaries/ProjectsSummaryFragment';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';

import SubnavFragment from '@fragments/shared/SubnavFragment';
import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment CollectionFragment on Collection {
        ${basePageFields}
        dek
        streamTiered {
            ...StreamTieredFragment
        }
        contributors {
            ...PersonSummaryFragment
        }
        imageCollage
        parent {
            value {
                ... on Blog {
                    ...BlogSummaryFragment
                    subNav {
                        ...SubnavFragment
                    }
                }
                ... on Center {
                    ...CenterSummaryFragment
                }
                ... on Program {
                    ...ProgramsSummaryFragment
                    subNav {
                        ...SubnavFragment
                    }
                }
                ... on Project {
                    ...ProjectsSummaryFragment
                    subNav {
                        ...SubnavFragment
                    }
                }
            }
        }
        seo {
            ...SeoFragment
        }
    }

    ${BlogSummaryFragment}
    ${CenterSummaryFragment}
    ${ProgramsSummaryFragment}
    ${ProjectsSummaryFragment}
    ${PersonSummaryFragment}
    ${SubnavFragment}
    ${StreamTieredFragment}
    ${SeoFragment}
`;
