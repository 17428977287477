import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment HeroProgramsProjectsFragment on HeroProgramsProjects {
        heroImage {
            ...ImageSummaryFragment
        }
        mobileHeroImage {
            ...ImageSummaryFragment
        }
        logo {
            ...ImageSummaryFragment
        }
    }

    ${ImageSummaryFragment}
`;
